import React, { Component } from "react";

import ApiHandler from "../../ApiHandler.js";
import PopularAnimeComponent from "../PopularAnimeComponent.js";
import AnimeComponent from "../AnimeComponent.js";
import Pagination from "../../lib/Pagination.js";

import { Link } from "react-router-dom";


import config from "config";
import { ContinueWatching } from "../profile/Profile.jsx";
import DonateButton from "./DonateButton.jsx";


export default class Sidebar extends Component {

	constructor(props) {

		super(props);

		this.state = {
			latest: [],
			latestPage: 0,
			latestAnimePage: 0,
			trendingAnimePage: 0
		}

		this.chatango = React.createRef();

	}

	componentDidMount() {

		ApiHandler
			.get("/latest")
			.then(d => this.setState({ latest: d }))

		this.randomItems = {

			randomTV: "/anime/random/TV"

		};

		["randomTV"]
			.forEach(this.refreshRandom.bind(this));

		const chatango = document.createElement("script");

		chatango.src = "//st.chatango.com/js/gz/emb.js";
		chatango.id = "cid0020000354776468037";
		chatango.innerText = '{"handle":"animefrenzyeu","arch":"js","styles":{"a":"ffffff","c":"e81e30","d":"e81e30","e":"ffffff","h":"ffffff","l":"efefef","m":"ffffff","q":"ffffff","t":0,"sbc":"bbbbbb"}}';
		chatango.style = "width: 100%;height: 480px;";
		chatango["data-cfasync"] = "false";


		this.chatangoElement = chatango;

	}

	render() {



		const { latest } = this.state;

		if (!latest || (latest instanceof Array))
			return (
				<h1>Loading...</h1>
			)

		if (this.chatango.current)
			this.chatango.current.appendChild(this.chatangoElement);

		return <>
			<div class="sidemain">
				<div className="noas">
					<div id="box-one" ref={this.chatango}  class="chat-box activeTab">
						{ !this.state.fixChatango && this.setState({ fixChatango: true }) }
					</div>
					<div class="continue-watching">
						{!this.props.noContinue && <ContinueWatching limit={5} title={<div class="lisb">Continue watching</div>} />}
					</div>
					<div class="randombox" id="random-anime">
						{this.buildRandom("randomTV")}
					</div>
					<div class="popularbox pbod">
						<div class="lisbox2">
							<div class="c">
								<div class="lisb">Popular today</div>
								<div class="ani" id="top-anime">
									{latest &&
										this.buildPopular(
											latest.trending_animes,
											this.state.trendingAnimePage,
											"trendingAnimePage",
											PopularAnimeComponent,
											"Trending anime"
										)}
								</div>
							</div>
						</div>
					</div>
					<div class="popularbox">
						<div class="lisbox2">
							<div class="c">
								<div class="lisb">Ongoing Anime</div>
								<div class="ani" id="top-anime">
									{latest &&
										this.buildOngoing(
											latest.ongoing_animes,
											this.state.latestAnimePage,
											"latestAnimePage",
											AnimeComponent,
											"Ongoing anime"
										)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</>

		

	}

	refreshRandom(key) {

		if (!this.state)

		this.setState({
			[key]: null
		});

		ApiHandler
			.get(this.randomItems[key])
			.then(d => this.setState({ [key]: d }));

	}

	buildRandom(key) {

		if (!this.state || !this.state[key]) return (
			<div className="loading">
			   Loading
			</div>
		);

		const anime = this.state[key];


		return (
			<div>
				<div class="c">
					<Link class="an" to={`/anime/${anime.slug}`}>
						<img
							class="coveri lazy"
							src={`${config.api.imageBase}${anime.image}`}
							alt={anime.name}
						/>
					</Link>
				</div>
				<div class="rnd">{anime.name}</div>
			</div>
		)

	} 

	buildPopular(items, current, key, component, title) {
		
		const pagination = new Pagination(items)
			.perPage(10)
			.padding(1, 1)
			.gutter(1, 1)
			.get(current);

		return (
			<div>
				{pagination.nav.currentPage.items && pagination.nav.currentPage.items.map((v, i) => 
					React.createElement(component, {
						payload: v,
						key: i + "key",
						index: i
					})
				)}
			</div>
		)

	}

	buildOngoing(items, current, key, component, title) {
		
		const pagination = new Pagination(items)
			.perPage(1000)
			.padding(1, 1)
			.gutter(1, 1)
			.get(current);

		return (
			<div>
				{pagination.nav.currentPage.items && pagination.nav.currentPage.items.map((v, i) =>
					React.createElement(component, {
						payload: v,
						key: i + "key",
						index: i
					})
				)}
			</div>
		)

	}

}